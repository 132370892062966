import { handleDefaultModal } from './utils';
import locale from './translations/ru.json';

const defaultId = 'default';
export const modalSelector = '#form-modal';
const formSelector = '#demonstration-form-modal';
const loginFormSelector = '#demonstration-form-modal-login';

const modal = document.querySelector(modalSelector);
const header = modal ? modal.querySelector('[data-modal-header]') : null;
const text = modal ? modal.querySelector('[data-modal-text]') : null;
const buttonText = modal ? modal.querySelector('[data-modal-button]') : null;
const modalHeaderInput = modal ? modal.querySelector('input[name=modal-header]') : null;

const demonstrationForm = document.querySelector(formSelector);
const loginForm = document.querySelector(loginFormSelector);
const nameField = demonstrationForm ? demonstrationForm.querySelector('[name=name]') : null;
const emailField = demonstrationForm ? demonstrationForm.querySelector('[name=email]') : null;
const phoneField = demonstrationForm ? demonstrationForm.querySelector('[name=phone]') : null;
const messageField = demonstrationForm ? demonstrationForm.querySelector('[name=message]') : null;

const loginCompanyField = loginForm ? loginForm.querySelector('[name=company]') : null;
const loginEmailField = loginForm ? loginForm.querySelector('[name=email]') : null;

const headerSwitcher = document.querySelector('.header_switcher');
const headerItems = document.querySelectorAll('.header_switcher__inner__item');
const firstForm = document.querySelector('.first_form');
const secondForm = document.querySelector('.second_form');
const modalContainer = document.querySelector('.modal-container');

headerItems.forEach((item, index) => {
  item.addEventListener('click', function () {
    headerItems.forEach((i) => i.classList.remove('active'));

    this.classList.add('active');

    if (index === 0) {
      if (secondForm) secondForm.classList.remove('active');
      if (firstForm) firstForm.classList.add('active');
      if (modalContainer) modalContainer.classList.remove('modal-container--xxl');
    } else {
      if (firstForm) firstForm.classList.remove('active');
      if (secondForm) secondForm.classList.add('active');
      if (modalContainer) modalContainer.classList.add('modal-container--xxl');
    }
  });
});

const content = {
  default: {
    header: locale['formsModal.default.header'],
    text: '',
    button: locale['formsModal.default.button'],
  },
  get_price: {
    header: locale['formsModal.getPrice.header'],
    text: locale['formsModal.getPrice.text'],
    button: locale['formsModal.getPrice.button'],
  },
  get_demo: {
    header: locale['formsModal.getDemo.header'],
    text: locale['formsModal.getDemo.text'],
    button: locale['formsModal.getDemo.button'],
  },
  get_free: {
    header: locale['formsModal.getFree.header'],
    text: locale['formsModal.getFree.text'],
    button: locale['formsModal.getFree.button'],
    hasSecondForm: true,
  },
  login: {
    header: locale['formsModal.getFree.header'],
    text: locale['formsModal.getFree.text'],
    button: locale['formsModal.getFree.button'],
    hasSecondForm: true,
    secondFormFirst: true,
  },
  get_offer: {
    header: locale['formsModal.getOffer.header'],
    text: locale['formsModal.getOffer.text'],
    button: locale['formsModal.getOffer.button'],
  },
  get_presentation: {
    header: locale['formsModal.getPresentation.header'],
    text: locale['formsModal.getPresentation.text'],
    button: locale['formsModal.getPresentation.button'],
  },
};

const onClickShowModal = (e) => {
  resetForm();
  changeContent(
    content[
      e.currentTarget && e.currentTarget.dataset.modalForm && content[e.currentTarget.dataset.modalForm]
        ? e.currentTarget.dataset.modalForm
        : defaultId
    ],
  );

  handleDefaultModal(modalSelector, () => {
    resetForm();
  });

  if (secondForm && secondForm.classList.contains('active') && headerSwitcher.classList.contains('active')) {
    if (modalContainer) modalContainer.classList.add('modal-container--xxl');
  } else {
    if (modalContainer) modalContainer.classList.remove('modal-container--xxl');
  }
};

const changeContent = (contentItem) => {
  if (header) {
    header.innerHTML = contentItem.header;
  }
  if (text) {
    text.innerHTML = contentItem.text;
  }
  if (buttonText) {
    buttonText.innerHTML = contentItem.button;
  }
  if (modalHeaderInput) {
    modalHeaderInput.value = contentItem.header;
  }
  if (headerSwitcher && contentItem.hasSecondForm) {
    if (!headerSwitcher.classList.contains('active')) headerSwitcher.classList.add('active');
    if (contentItem.secondFormFirst) {
      if (secondForm) secondForm.classList.add('active');
      if (firstForm) firstForm.classList.remove('active');
      if (modalContainer) modalContainer.classList.add('modal-container--xxl');
      headerItems.forEach((i) => i.classList.remove('active'));
      headerItems.forEach((item, index) => {
        if (index === 1) {
          item.classList.add('active');
        }
      });
    } else {
      if (secondForm) secondForm.classList.remove('active');
      if (firstForm) firstForm.classList.add('active');
      if (modalContainer) modalContainer.classList.remove('modal-container--xxl');
      headerItems.forEach((i) => i.classList.remove('active'));
      headerItems.forEach((item, index) => {
        if (index === 0) {
          item.classList.add('active');
        }
      });
    }
  } else {
    if (headerSwitcher.classList.contains('active')) {
      headerSwitcher.classList.remove('active');
      if (secondForm && secondForm.classList.contains('active')) {
        if (secondForm) secondForm.classList.remove('active');
        if (firstForm) firstForm.classList.add('active');
        if (modalContainer) modalContainer.classList.remove('modal-container--xxl');
        headerItems.forEach((i) => i.classList.remove('active'));
        headerItems.forEach((item, index) => {
          if (index === 0) {
            item.classList.add('active');
          }
        });
      }
    }
  }
};

const resetForm = () => {
  if (demonstrationForm) {
    demonstrationForm.classList.remove('success');
    demonstrationForm.reset();
  }
  if (loginForm) {
    loginForm.classList.remove('success');
    loginForm.reset();
  }
  if (nameField) {
    nameField.parentElement.classList.remove('error');
    nameField.disabled = false;
  }
  if (emailField) {
    emailField.parentElement.classList.remove('error');
    emailField.disabled = false;
  }
  if (phoneField) {
    phoneField.parentElement.classList.remove('error');
    phoneField.disabled = false;
  }
  if (messageField) {
    messageField.disabled = false;
  }
  if (loginCompanyField) {
    loginCompanyField.parentElement.classList.remove('error');
    loginCompanyField.disabled = false;
  }
  if (loginEmailField) {
    loginEmailField.parentElement.classList.remove('error');
    loginEmailField.disabled = false;
  }
};

const modalButtons = document.querySelectorAll('[data-modal-form]:not([data-modal-form="get_calc_roi"])');

if (modalButtons) {
  modalButtons.forEach((btn) => btn.addEventListener('click', onClickShowModal));
}
