export const handleDefaultModal = (modalId, onClose = null) => {
  const modal = document.querySelector(modalId);
  const closeBtn = modal.querySelectorAll('[data-modal=close-modal]');

  const handleCloseModal = () => {
    modal.style.visibility = 'hidden';
    modal.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    modal.classList.remove('visible');
    document.body.style.overflow = 'initial';
    closeBtn.forEach((btn) => btn.removeEventListener('click', handleCloseModal));
    modal.removeEventListener('click', handleCloseModalByWrapper);

    if (onClose) {
      onClose();
    }
  };

  const handleCloseModalByWrapper = (e) => {
    if (e.target.classList.contains('modal-wrapper')) {
      handleCloseModal();
    }
  };

  closeBtn.forEach((btn) => btn.addEventListener('click', handleCloseModal));
  modal.addEventListener('click', handleCloseModalByWrapper);

  modal.style.visibility = 'visible';
  modal.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
  modal.classList.add('visible');
  document.body.style.overflow = 'hidden';
};
